const breadcrumbsMixin = {
  methods: {
    breadcrumbsItems (breadcrumbs, localePath) {
      let items = [
        {
          text: process.env.SITE_NAME,
          disabled: false,
          to: process.env.BASE_URL + localePath({ name: 'index' })
        }
      ]

      items = items.concat(breadcrumbs)

      return items
    },

    breadcrumbsLdJson (breaditems) {
      const items = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: []
      }
      breaditems.forEach((e, i) => {
        items.itemListElement.push({
          '@type': 'ListItem',
          position: i + 1,
          name: e.text,
          item: e.href ?? e.to
        })
      })

      return items
    }
  }
}

export default breadcrumbsMixin
