
import breadcrumbsMixin from '~/mixins/breadcrumbs/breadcrumbsMixin'

export default {
  mixins: [
    breadcrumbsMixin
  ],
  async asyncData ({ redirect, localePath, $auth }) {
    if (!!$auth.$state?.loggedIn) {
      redirect(302, localePath({ name: 'dashboard' }))
    }

    const data = {
      breaditems: await breadcrumbsMixin.methods.breadcrumbsItems([], localePath),
      breadldjson: await breadcrumbsMixin.methods.breadcrumbsLdJson([])
    }

    return data
  },

  head () {
    return {
      title: this.$t('index.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('index.desc')
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('index.desc')
        },
        { hid: 'og:title', name: 'og:title', content: this.$t('index.title') },
        { hid: 'og:url', name: 'og:url', content: this.$config.baseUrl + this.localePath({ name: 'index' }) }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: this.breadldjson
        }
      ]
    }
  },

  created () {
    if (!!this.$auth.loggedIn) {
      this.$router.replace(this.localePath({ name: 'dashboard' }))
    }
  }
}
